import { AddOutlined, DeleteOutline, SaveOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

interface IActionButtons {
    isNew?: boolean,
    loading: boolean,
    handleDelete: () => void;
}

export const ActionButton = ({ isNew, loading, handleDelete }: IActionButtons) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button disabled={loading} type='submit' variant='contained' startIcon={isNew ? <AddOutlined /> : <SaveOutlined />}>
                {isNew ? 'Создать' : 'Сохранить'}
            </Button>
            {!isNew && (
                <Button type='button' onClick={handleDelete} disabled={loading} startIcon={<DeleteOutline />} variant='outlined' color='error'>
                    Удалить
                </Button>
            )}
        </Box>
    )
}
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import { useAuth, useSnackbar } from '$hooks/index';

export const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { user, login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { openSnackbar } = useSnackbar();

    useEffect(() => {
        if (user) navigate('/users');
    }, [user, navigate]);

    const fromPage = location.state?.from?.pathname || '/users';

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const req = await login({ email, password });
        if (req?.success) {
            navigate(fromPage, { replace: true });
        } else {
            openSnackbar(`Ошибка авторизации: ${req?.error}`, 'error')
        }
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'grey.50',
                py: 12,
                px: 4,
            }}
        >
            <Container maxWidth="xs">
                <Paper sx={{ p: 4, borderRadius: 2 }}>
                    <Box sx={{ mb: 4 }}>
                        <Typography component="h1" variant="h5" align="center">
                            Proctodoc.ru - Admin
                        </Typography>
                    </Box>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="primary"
                        >
                            Войти
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

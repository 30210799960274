import { createBrowserRouter, redirect } from 'react-router-dom';
import { LoginPage } from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import { UsersPage } from '$pages/Users';
import { Layout } from '$components/index';
import { AppointmentsPage } from '$pages/Appointments';
import { AppointmentPage } from '$pages/Appointment';

const routes = createBrowserRouter([
    {
        path: '/',
        loader: () => redirect('/appointments'),
    },
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/users',
        element: (
            <PrivateRoute>
                <Layout children={<UsersPage />} />
            </PrivateRoute>
        ),
    },
    {
        path: '/appointments',
        element: (
            <PrivateRoute>
                <Layout children={<AppointmentsPage />} />
            </PrivateRoute>
        ),
    },
    {
        path: '/appointments/:id',
        element: (
            <PrivateRoute>
                <Layout children={<AppointmentPage />} />
            </PrivateRoute>
        ),
    },
]);

export default routes;
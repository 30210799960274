import React from 'react';
import { Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

interface TableProps {
    data: any[];
    headers: string[];
    keys: string[];
    onRowClick?: (item: any) => void;
    renderers?: ((value: any, record: any) => React.ReactNode | null)[];
}

export const Table: React.FC<TableProps> = ({ data, headers, keys, onRowClick, renderers }) => {
    return (
        <TableContainer component={Paper} sx={{ border: 1, borderColor: 'grey.200', borderRadius: 2 }}>
            <MuiTable>
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell key={index} sx={{ fontWeight: 'bold', backgroundColor: 'background.default' }}>
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow
                            key={index}
                            hover={!!onRowClick}
                            sx={{ cursor: onRowClick ? 'pointer' : 'default' }}
                            onClick={() => onRowClick && onRowClick(item)}
                        >
                            {keys.map((key, idx) => (
                                <TableCell key={idx}>
                                    {renderers && renderers[idx] ? (
                                        renderers[idx](item[key], item)
                                    ) : (
                                        <Typography variant="body2" color="textPrimary">
                                            {item[key]}
                                        </Typography>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { useAuth } from "$hooks/index";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider } from "$components/SnackBar";
import theme from "./Theme";

function App() {
  const { loading } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <RouterProvider router={router} />

        )}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App

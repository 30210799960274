import { createTheme } from "@mui/material";

const theme = createTheme({
	components: {
		MuiInputLabel: {
			defaultProps: {
				shrink: true,
			},
			styleOverrides: {
				root: {
                    background: '#fff'
                    
				},
			},
		},
	},
	palette: {
		primary: {
			main: "#43BDD6",
			contrastText: "#fff",
		},
		secondary: {
			main: "#2E8C9E",
		},
		text: {
			primary: "#222",
		},
		background: {
			default: "#DCDCDC",
			paper: "#fff",
		},
	},
});

export default theme;

import api from "$api";
import { User } from "$types/auth";
import { ServiceResult } from "$types/service";
import { AxiosError } from "axios";

export const getAllUsers = async (): Promise<
	ServiceResult<{ users: User[] }>
> => {
	try {
		const response = await api.get("/users");
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const createUser = async (
	userData: Omit<User, "id">,
): Promise<ServiceResult<User>> => {
	try {
		const response = await api.post("/users", userData);
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const updateUser = async (
	userId: number | string,
	userData: Partial<User>,
): Promise<ServiceResult<User>> => {
	try {
		const response = await api.put(`/users/${userId}`, userData);
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const deleteUser = async (
	userId: number | string,
): Promise<ServiceResult<string>> => {
	try {
		const response = await api.delete(`/users/${userId}`);
		return { success: true, data: response.data.message };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

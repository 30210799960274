import React from 'react';
import Box from '@mui/material/Box';
import { Header } from '..';
interface LayoutProps {
    children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Box sx={{ minHeight: '100vh', backgroundColor: 'grey.100' }}>
            <Header />
            {children}
        </Box>
    );
};

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '$hooks/index';

const PrivateRoute: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const { user } = useAuth();
    const location = useLocation();

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children || 'Not found';
};

export default PrivateRoute;
import React, { useState } from 'react';
import { updateUser, deleteUser, createUser } from '$services/userService';
import { User } from '$types/auth';
import { ActionButton, PageLayout } from '$components/index';
import { TextField, MenuItem, Box } from '@mui/material';
import { useSnackbar, useLoading } from '$hooks/index';

interface UserPageProps {
    user: User | null;
    onClose: () => void;
}


export const UserPage: React.FC<UserPageProps> = ({ user, onClose }) => {
    const [name, setName] = useState(user?.name || '');
    const [email, setEmail] = useState(user?.email || '');
    const [role, setRole] = useState(user?.role || '');
    const [password, setPassword] = useState('');

    const { loading, withLoading } = useLoading();
    const { openSnackbar } = useSnackbar();

    const isNewUser = user === null;

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isNewUser) {
            const res = await withLoading(() => createUser({ name, email, role, password }));
            if (res.success) {
                openSnackbar('Пользователь успешно создан', 'success');
                onClose();
            } else {
                openSnackbar(`Не удалось создать пользователя: ${res.error}`, 'error');
            }
        } else {
            const res = await withLoading(() => updateUser(user.id, { name, email, role, password }));
            if (res.success) {
                openSnackbar('Пользователь успешно обновлен', 'success');
                onClose();
            } else {
                openSnackbar(`Не удалось обновить пользователя: ${res.error}`, 'error');
            }
        }
    };


    const handleDelete = async () => {
        if (isNewUser) return;
        if (window.confirm('Вы уверены, что хотите удалить этого пользователя?')) {
            const res = await withLoading(() => deleteUser(user.id));
            if (res.success) {
                openSnackbar('Пользователь успешно удален', 'success');
                onClose();
            } else {
                openSnackbar('Не удалось удалить пользователя', 'error');
            }
        }
    };

    return (
        <PageLayout buttonFn={onClose} buttonText='Назад' title={user?.id ? 'Редактирование пользователя' : 'Создание пользователя'} buttonIcon='back' type='form'>
            <Box component='form' onSubmit={handleSubmit} sx={{ width: '100%' }}>
                <Box mb={4}>
                    <TextField
                        label="Имя"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </Box>
                <Box mb={4}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Box>
                <Box mb={4}>
                    <TextField
                        label="Роль"
                        variant="outlined"
                        select
                        fullWidth
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required
                    >
                        <MenuItem value="admin">Админ</MenuItem>
                        <MenuItem value="secretary">Секретарь</MenuItem>
                        <MenuItem value="viewer">Гость</MenuItem>
                    </TextField>
                </Box>
                <Box mb={6}>
                    <TextField
                        label="Новый пароль"
                        variant="outlined"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Box>


                <ActionButton isNew={isNewUser} handleDelete={handleDelete} loading={loading} />
            </Box>
        </PageLayout >
    );
};

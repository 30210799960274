import api from "$api";
import { User, LoginRequest, Tokens } from "$types/auth";
import { ServiceResult } from "$types/service";
import { AxiosError } from "axios";

export const loginUser = async (
	data: LoginRequest,
): Promise<ServiceResult<Tokens>> => {
	try {
		const response = await api.post("/users/login", data);
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const getUserData = async (
	token: string,
): Promise<ServiceResult<User>> => {
	try {
		const response = await api.get("/users/me", {
			headers: { Authorization: `Bearer ${token}` },
		});
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const refreshTokens = async (refreshToken: string) => {
	try {
		const response = await api.post("/users/refresh", { refreshToken });
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			error: (error as AxiosError).message || "Failed to refresh tokens",
		};
	}
};

export const logoutUser = async () => {
	try {
		const response = await api.post("/users/logout");
		return { success: true, data: response.data };
	} catch (error) {
		return {
			success: false,
			error: (error as AxiosError).message || "Failed to delete token",
		};
	}
};

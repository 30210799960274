import api from "$api";
import { IAppointment } from "$types/appointments";
import { ServiceResult } from "$types/service";
import { AxiosError } from "axios";

export const getAllAppointments = async (
	page: number,
	limit: number,
	orderBy?: string,
	order?: "ASC" | "DESC",
	patientName?: string,
	appointmentDate?: string,
): Promise<
	ServiceResult<{
		appointments: IAppointment[];
		currentPage: number;
		totalPages: number;
	}>
> => {
	try {
		const params = {
			page,
			limit,
			orderBy,
			order,
			patientName,
			appointmentDate,
		};

		const response = await api.get("/appointments", { params });
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const getAppointmentById = async (
	appointmentId: number | string,
): Promise<ServiceResult<IAppointment>> => {
	try {
		const response = await api.get(`/appointments/${appointmentId}`);
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const createAppointment = async (
	appointmentData: Omit<IAppointment, "id">,
): Promise<ServiceResult<IAppointment>> => {
	try {
		const response = await api.post("/appointments", appointmentData);
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const updateAppointment = async (
	appointmentId: number,
	appointmentData: Partial<IAppointment>,
): Promise<ServiceResult<IAppointment>> => {
	try {
		const response = await api.put(
			`/appointments/${appointmentId}`,
			appointmentData,
		);
		return { success: true, data: response.data };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

export const deleteAppointment = async (
	appointmentId: number | string,
): Promise<ServiceResult<string>> => {
	try {
		const response = await api.delete(`/appointments/${appointmentId}`);
		return { success: true, data: response.data.message };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};

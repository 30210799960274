import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import { DocumentScanner as DocumentTextIcon, LogoutOutlined, People as UserIcon } from '@mui/icons-material';
import { useAuth } from '$hooks/index';


export const Header = () => {
    const { logout } = useAuth();
    const menuItems = [
        { path: '/appointments', label: 'Записи', icon: <DocumentTextIcon /> },
        { path: '/users', label: 'Пользователи', icon: <UserIcon /> },
    ];

    return (
        <AppBar position="static" sx={{ bgcolor: 'background.paper', boxShadow: '0' }} >
            <Toolbar sx={{ maxWidth: 'lg', margin: '0 auto', width: '100%' }}>
                <Box sx={{ display: 'flex', flexGrow: 1, gap: 1, alignItems: 'center' }}>
                    {menuItems.map((item) => (
                        <Button
                            key={item.path}
                            component={NavLink}
                            to={item.path}
                            startIcon={item.icon}
                            sx={{
                                color: 'text.secondary',
                                '&.active': {
                                    color: 'primary.main',
                                },
                                '&:hover': {
                                    color: 'primary.main',
                                },
                            }}
                        >
                            {item.label}
                        </Button>
                    ))}
                </Box>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={logout}
                    sx={{
                        ml: 2,
                    }}
                    startIcon={<LogoutOutlined />}
                >
                    Выход
                </Button>
            </Toolbar>
        </AppBar>
    );
};

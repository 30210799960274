import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { updateAppointment, deleteAppointment, createAppointment, getAppointmentById } from '$services/appointmentService';
import { MessengerType, IAppointment, ISession } from '$types/appointments';
import { PageLayout } from '$components/PageLayout';
import { format, parseISO } from 'date-fns';
import { AddOutlined, Cancel, CancelOutlined, CheckCircle, CheckCircleOutline, DeleteOutline } from '@mui/icons-material';
import { formatDate } from '$utils/index';
import { Box, TextField, MenuItem, Typography, FormControl, InputLabel, Select, SelectChangeEvent, Button, FormGroup, Paper, } from '@mui/material';
import { useAuth, useLoading, useSnackbar } from '$hooks/index';
import { ActionButton } from '$components/ActionButtons';
import PhoneInput from '$components/PhoneInput';

const formateISOdate = (isoDate: string) => {
    if (!isoDate) return '';
    return format(parseISO(isoDate), "yyyy-MM-dd'T'HH:mm");
};

const paperStyle = { p: 4, borderRadius: 2, width: { xs: '100%', sm: 600 }, mx: 'auto' }

export const AppointmentPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { loading, withLoading } = useLoading();
    const { dictionary } = useAuth();
    const location = useLocation();
    const [formValues, setFormValues] = useState({
        patientName: '',
        notificationDate: '',
        sessions: [{ date: '', doctorId: '', procedureId: '' }] as ISession[],
        messengerType: '' as MessengerType,
        confirmed: false,
        createdAt: '',
        phone: ''
    });
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();

    const isNewAppointment = id === 'new';

    useEffect(() => {
        const fetchAppointment = async () => {
            if (!isNewAppointment && !location.state) {
                const res = await withLoading(() => getAppointmentById(id!));
                if (res.success) {
                    setFormValues(res.data);
                } else {
                    openSnackbar(`Не удалось загрузить запись: ${res.error}`, 'error');
                    navigate('/appointments');
                }
            } else if (location.state) {
                setFormValues(location.state as IAppointment);
            }
        };
        fetchAppointment();
    }, []);

    const addSession = () => {
        setFormValues(prev => ({
            ...prev,
            sessions: [...prev.sessions, { doctorId: '', procedureId: '', date: '' }]
        }));
    };


    // TODO: update handle date logic, include all dates rules (all session in 1 day etc...)
    const updateSession = (index: number, field: keyof ISession, value: string) => {
        setFormValues(prev => ({
            ...prev,
            sessions: prev.sessions.map((session, i) =>
                i === index ? { ...session, [field]: value } : session
            )
        }));
    };

    const removeSession = (index: number) => {
        if (formValues.sessions.length === 1) return;
        setFormValues(prev => ({
            ...prev,
            sessions: prev.sessions.filter((_, i) => i !== index)
        }));
    };


    const handleInputChange = (e: React.ChangeEvent<any> | SelectChangeEvent) => {
        const { name, value } = e.target;
        if (name === 'appointmentDate') {
            const notificationDate = new Date(value);
            notificationDate.setDate(notificationDate.getDate() - 1);
            const hours = notificationDate.getHours();
            if (hours > 19 || hours < 11) {
                notificationDate.setHours(hours > 19 ? 19 : 11);
            }
            return setFormValues((prev) => ({
                ...prev,
                appointmentDate: value,
                notificationDate: notificationDate.toISOString(),
            }));
        }
        if (name === 'notificationDate') {
            const appointmentDate = new Date(formValues.sessions[0]?.date);
            const notificationDate = new Date(value);
            if (notificationDate > appointmentDate) {
                openSnackbar('Время уведомления не может быть позже времени первой записи', 'info');
                return;
            }
        }
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const res = await withLoading(() =>
            isNewAppointment
                ? createAppointment(formValues as IAppointment)
                : updateAppointment(Number(id!), formValues as IAppointment)
        );
        if (res.success) {
            openSnackbar(isNewAppointment ? 'Запись успешно создана' : 'Запись успешно обновлена', 'success');
            navigate('/appointments');
        } else {
            openSnackbar(`Ошибка при обновлении записи: ${res.error}`, 'error');
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Вы уверены, что хотите удалить эту запись?')) {
            const res = await withLoading(() => deleteAppointment(id!));
            if (res.success) {
                openSnackbar('Запись успешно удалена', 'success');
                navigate('/appointments');
            } else {
                openSnackbar(`Ошибка при удалении записи: ${res.error}`, 'error');
            }
        }
    };

    const handlePhone = (phone: string) => {
        setFormValues({ ...formValues, phone })
    }

    const handleConfirmed = async () => {
        const formData = {
            ...formValues,
            confirmed: !formValues.confirmed,
        };
        const res = await withLoading(() => updateAppointment(Number(id), formData as IAppointment));
        if (res.success) {
            openSnackbar(`Запись успешно обновлена, статус: ${res.data.confirmed ? 'подтверждена' : 'отменена'}`, 'success');
            setFormValues(res.data);
        } else {
            openSnackbar(`Ошибка при обновлении записи: ${res.error}`, 'error');
        }
    };

    const doctorOptions = useMemo(() => dictionary?.doctors.map((doctor) => (
        <MenuItem key={doctor.id} value={doctor.id}>{doctor.label}</MenuItem>
    )), [dictionary?.doctors]);

    const procedureOptions = useMemo(() => dictionary?.procedures.map((procedure) => (
        <MenuItem key={procedure.id} value={procedure.id}>{procedure.label}</MenuItem>
    )), [dictionary?.procedures]);


    const renderConfirmButton = useCallback(() => {
        if (isNewAppointment) return null;
        const { confirmed } = formValues;
        return (
            <Button
                onClick={handleConfirmed}
                disabled={loading}
                type="button"
                color={confirmed ? 'error' : 'success'}
                variant="contained"
                startIcon={confirmed ? <CancelOutlined /> : <CheckCircleOutline />}
            >
                {`${confirmed ? 'Отменить' : 'Подтвердить'} запись`}
            </Button>
        );
    }, [formValues, isNewAppointment, loading]);

    const { confirmed } = formValues;

    return (
        <PageLayout
            title={isNewAppointment ? 'Создать запись' : 'Редактировать запись'}
            buttonFn={() => navigate('/appointments')}
            buttonText="Назад"
            buttonIcon="back"
        >
            <Box component='form' onSubmit={handleSubmit} sx={{ width: '100%' }}>
                <Paper sx={paperStyle}>
                    <Box mb={3}>
                        <Typography variant="h6">Данные о пациенте</Typography>
                    </Box>

                    <Box mb={4}>
                        <TextField
                            label="ФИО Пациента"
                            variant="outlined"
                            fullWidth
                            id="patientName"
                            name="patientName"
                            value={formValues.patientName}
                            onChange={handleInputChange}
                            required
                        />
                    </Box>
                    <PhoneInput value={formValues.phone} onPhoneChange={handlePhone} />
                </Paper>
                {
                    formValues.sessions.map((session, index) => (
                        <Paper key={`${session.doctorId}-${index}`} sx={{ ...paperStyle, mt: 2 }}>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={3}>
                                <Typography variant="h6">Прием {formValues.sessions.length > 1 ? index + 1 : ''}</Typography>
                                <Button onClick={() => removeSession(index)} color="error" sx={{ p: 1, minWidth: 'unset' }}>
                                    <DeleteOutline />
                                </Button>
                            </Box>
                            <Box mb={4}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id={`doctor-label-${index}`}>Доктор</InputLabel>
                                    <Select
                                        labelId={`doctor-label-${index}`}
                                        id={`doctor-${index}`}
                                        value={session.doctorId}
                                        onChange={(e) => updateSession(index, 'doctorId', e.target.value)}
                                        label="Доктор"
                                        required
                                    >
                                        <MenuItem value="">
                                            <em>Выберите доктора</em>
                                        </MenuItem>
                                        {doctorOptions}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box mb={4}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id={`procedure-label-${index}`}>Процедура</InputLabel>
                                    <Select
                                        labelId={`procedure-label-${index}`}
                                        id={`procedure-${index}`}
                                        value={session.procedureId}
                                        onChange={(e) => updateSession(index, 'procedureId', e.target.value)}
                                        label="Процедура"
                                        required
                                    >
                                        <MenuItem value="">
                                            <em>Выберите процедуру</em>
                                        </MenuItem>
                                        {procedureOptions}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box mb={4}>
                                <TextField
                                    label="Дата и время"
                                    variant="outlined"
                                    fullWidth
                                    type="datetime-local"
                                    value={formateISOdate(session.date)}
                                    onChange={(e) => updateSession(index, 'date', e.target.value)}
                                    disabled={index !== 0 && !formValues.sessions[index - 1].date}
                                    required
                                />
                            </Box>

                        </Paper>
                    ))
                }
                <Box m='auto' mt={2} mb={2} sx={{ width: paperStyle.width }}>
                    <Button
                        onClick={addSession}
                        variant="contained"
                        fullWidth
                        endIcon={<AddOutlined />}
                    >
                        Добавить прием
                    </Button>
                </Box>
                <Paper sx={{ ...paperStyle, mt: 2 }}>
                    <Box mb={3}>
                        <Typography variant="h6">Уведомление и статус записи</Typography>
                    </Box>
                    <Box mb={4}>
                        <TextField
                            label="Время уведомления"
                            variant="outlined"
                            fullWidth
                            id="notificationDate"
                            name="notificationDate"
                            type="datetime-local"
                            value={formateISOdate(formValues.notificationDate)}
                            onChange={handleInputChange}
                            disabled={!formValues.sessions?.[0]?.date}
                            required
                        />
                    </Box>
                    <Box mb={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="messengerType-label">Канал связи</InputLabel>
                            <Select
                                labelId="messengerType-label"
                                id="messengerType"
                                name="messengerType"
                                value={formValues.messengerType}
                                onChange={handleInputChange}
                                label="Канал связи"
                                required
                            >
                                <MenuItem value="">
                                    <em>Выберите канал связи</em>
                                </MenuItem>
                                <MenuItem value="wa">What's Up</MenuItem>
                                <MenuItem value="tg">Telegram</MenuItem>
                                <MenuItem value="call">Звонок</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {!isNewAppointment && (
                        <>
                            <Box mb={4}>
                                <Typography variant="body1" gutterBottom>
                                    Запись
                                </Typography>

                                <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} flexWrap={'wrap'}>
                                    <Box display="flex" alignItems="center">
                                        {confirmed ?
                                            <CheckCircle color='success' style={{ marginRight: 8 }} />
                                            : <Cancel color='error' style={{ marginRight: 8 }} />
                                        }
                                        <Typography variant='body1'>{`${confirmed ? 'Подтверждена' : 'Не подтвержена'}`}</Typography>
                                    </Box>
                                    {renderConfirmButton()}
                                </Box>
                            </Box>
                            {formValues.createdAt && (
                                <Box mb={4}>
                                    <Typography variant="body1" gutterBottom>
                                        Время создания записи: <Typography variant='caption'>{formatDate(formValues.createdAt)}</Typography>
                                    </Typography>
                                </Box>
                            )}
                        </>
                    )}
                    <ActionButton isNew={isNewAppointment} loading={loading} handleDelete={handleDelete} />
                </Paper>
            </Box >
        </PageLayout >
    );
};

import { useState } from "react";

export const useLoading = () => {
	const [loading, setLoading] = useState(false);

	const withLoading = async <T>(
		asyncFunction: () => Promise<T>,
	): Promise<T> => {
		setLoading(true);
		try {
			const result = await asyncFunction();
			return result;
		} finally {
			setLoading(false);
		}
	};

	return { loading, withLoading };
};

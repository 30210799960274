import React, { useCallback } from 'react';
import { Button, Container, Box, Typography, Paper } from '@mui/material';
import { ArrowBack, Add } from '@mui/icons-material';

interface IPageLayout {
    buttonText?: string;
    buttonFn?: () => void;
    children: React.ReactNode;
    title: string;
    buttonIcon?: 'back' | 'plus';
    type?: 'table' | 'form'
}

const LAYOUT_STYLE = {
    table: {
        width: '100%',
        mx: 0
    },
    form: {
        width: { xs: '100%', sm: 600 },
        mx: 'auto'
    }
}

export const PageLayout: React.FC<IPageLayout> = ({ buttonFn, buttonText, buttonIcon, children, title, type }) => {

    const isBack = buttonIcon === 'back';

    const renderBody = useCallback(() => {
        if (!type) return (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {children}
            </Box>
        )
        const { width, mx } = LAYOUT_STYLE[type];

        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Paper sx={{ p: 4, borderRadius: 2, width, mx }}>
                    {children}
                </Paper>
            </Box>
        )
    }, [children, type])

    return (
        <Container maxWidth="lg" sx={{ p: 0, pb: 4, mt: 4 }}>
            <Box sx={{ mb: 3, px: 4 }}>
                <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {title}
                </Typography>
                {buttonFn && buttonText && (
                    <Box>
                        <Button
                            variant={isBack ? 'outlined' : "contained"}
                            onClick={buttonFn}
                            startIcon={isBack ? <ArrowBack /> : <Add />}
                        >
                            {buttonText}
                        </Button>
                    </Box>
                )}
            </Box>
            {renderBody()}
        </Container>
    );
};

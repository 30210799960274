import React, { createContext, useEffect, useState } from 'react';
import { getAccessToken, getRefreshToken, removeAccessToken, removeRefreshToken, setAccessToken, setRefreshToken } from '$utils/auth';
import { loginUser, getUserData, refreshTokens, logoutUser } from '$services/authService'
import { LoginRequest, User } from '$types/auth';
import { ServiceResult } from '$types/service';
import { IDictionaryArray } from '$types/dictionary';
import { getDictionary } from '$services/dictionaryService';

interface AuthContextType {
    user: User | null;
    login: (data: LoginRequest) => Promise<ServiceResult<User> | null>;
    logout: () => void;
    getUser: (token: string) => Promise<ServiceResult<User> | null>
    loading: boolean
    dictionary: IDictionaryArray | null
}

export const AuthContext = createContext<AuthContextType>({
    user: null,
    login: () => Promise.resolve(null),
    logout: () => { },
    getUser: () => Promise.resolve(null),
    loading: true,
    dictionary: null
});

const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [dictionary, setDictionary] = useState<IDictionaryArray | null>(null)
    const [loading, setLoading] = useState(true);

    const login = async (data: LoginRequest) => {
        const req = await loginUser(data);
        if (req.success) {
            const { data } = req;
            setAccessToken(data.accessToken);
            setRefreshToken(data.refreshToken);
            return getUser(data.accessToken);
        } else {
            return req;
        }
    }

    const fetchDictionary = async () => {
        const res = await getDictionary();
        if (res.success) {
            setDictionary(res.data);
            return res.data;
        } else {
            console.log('Dictionary error: ', res.error);
            throw new Error(res.error)
        }
    };


    const getUser = async (token: string) => {
        const req = await getUserData(token);
        if (req.success) {
            await fetchDictionary();
            setUser(req.data)
        }
        return req;
    }

    const logout = async () => {
        await logoutUser();
        setUser(null);
        removeAccessToken();
        removeRefreshToken();
    }


    useEffect(() => {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();
        if (accessToken && refreshToken) {
            getUser(accessToken)
                .then(() => setLoading(false))
                .catch(() => {
                    refreshTokens(refreshToken)
                        .then((res) => {
                            if (res.success) {
                                setAccessToken(res.data.accessToken);
                                return getUser(res.data.accessToken);
                            } else {
                                logout();
                                setLoading(false);
                                return null;
                            }
                        })
                        .then(() => setLoading(false))
                        .catch(() => {
                            logout();
                            setLoading(false);
                        });
                });
        } else {
            setLoading(false);
        }
    }, []);

    const contextValue = { user, login, logout, loading, getUser, dictionary }
    return (
        <AuthContext.Provider value={contextValue}>
            {children ? children : null}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAllAppointments } from '$services/appointmentService';
import { useNavigate } from 'react-router-dom';
import { IAppointment, ISession } from '$types/appointments';
import { PageLayout } from '$components/PageLayout';
import { Table } from '$components/Table';
import { formatDate } from '$utils/index';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAuth, useSnackbar } from '$hooks/index';

const LIMIT = 30;

const TABLE_HEADERS = ['Пациент', 'Процедура', 'Время записи', 'Время уведомления', 'Подтверждено'];

const renderConfirmed = (confirmed: boolean) => {
    return confirmed ? (
        <CheckCircle color='success' />
    ) : (
        <Cancel color='error' />
    );
};

const renderDate = (dateString: string | ISession[]) => {
    const data = Array.isArray(dateString) ? dateString[0].date : dateString;
    return (
        <div>{formatDate(data)}</div>
    );
};


export const AppointmentsPage = () => {
    const [orderBy, setOrderBy] = useState('createdAt');
    const [order, setOrder] = useState<'ASC' | 'DESC'>('DESC');
    const [appointments, setAppointments] = useState<IAppointment[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();
    const { dictionary } = useAuth()


    useEffect(() => {
        const fetchAppointments = async () => {
            const res = await getAllAppointments(currentPage, LIMIT, orderBy, order);
            if (res.success) {
                setAppointments(res.data.appointments);
                setTotalPages(res.data.totalPages);
            } else {
                openSnackbar('Ошибка загрузки записей', 'error');
            }
        };
        fetchAppointments();
    }, [currentPage, orderBy, order]);

    const handleAppointmentClick = (appointment: IAppointment) => {
        navigate(`/appointments/${appointment.id}`);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleCreateAppointment = () => navigate('/appointments/new');

    const handleOrderByChange = (event: SelectChangeEvent<string>) => {
        setOrderBy(event.target.value);
    };

    const handleOrderChange = (event: SelectChangeEvent<'ASC' | 'DESC'>) => {
        setOrder(event.target.value as 'ASC' | 'DESC');
    };

    const proceduresMap = useMemo(() =>
        dictionary?.procedures.reduce((map, { id, label }) => ({ ...map, [id]: label }), {} as Record<string, string>),
        [dictionary]
    );
    const renderProcedures = useCallback((sessions: ISession[]) => {
        if (!proceduresMap) return null;

        const procedureNames = sessions.map(session => proceduresMap[session.procedureId])
        return (
            <div>{procedureNames.join(', ')}</div>
        );
    }, [dictionary]);


    return (
        <PageLayout buttonFn={handleCreateAppointment} buttonText='Создать' title={'Записи'} buttonIcon='plus' type='table'>
            <Box mb={4} sx={{ display: 'flex', gap: 1 }}>
                <Select
                    value={orderBy}
                    onChange={handleOrderByChange}
                    variant="outlined"
                >
                    <MenuItem value="createdAt">Дата создания</MenuItem>
                    {/* <MenuItem value="appointmentDate">Время записи</MenuItem> */}
                    <MenuItem value="notificationDate">Время уведомления</MenuItem>
                    <MenuItem value="updatedAt">Дата изменения</MenuItem>
                </Select>
                <Select
                    value={order}
                    onChange={handleOrderChange}
                    variant="outlined"
                >
                    <MenuItem value="ASC">По возрастанию</MenuItem>
                    <MenuItem value="DESC">По убыванию</MenuItem>
                </Select>
            </Box>
            <Table
                onRowClick={handleAppointmentClick}
                data={appointments}
                headers={TABLE_HEADERS}
                keys={['patientName', 'sessions', 'sessions', 'notificationDate', 'confirmed']}
                renderers={[null, renderProcedures, renderDate, renderDate, renderConfirmed]}
            />
            {/* <Box mt={4} display="flex" justifyContent="center">
                {Array.from({ length: totalPages }, (_, index) => (
                    <Button
                        key={index}
                        variant={currentPage === index + 1 ? "contained" : "outlined"}
                        color={currentPage === index + 1 ? "primary" : "default"}
                        onClick={() => handlePageChange(index + 1)}
                        sx={{ mx: 0.5 }}
                    >
                        {index + 1}
                    </Button>
                ))}
            </Box> */}
        </PageLayout>
    );
};

import axios from "axios";
import {
	getAccessToken,
	setAccessToken,
	getRefreshToken,
	setRefreshToken,
	removeAccessToken,
	removeRefreshToken,
} from "$utils/auth";

const api = axios.create({
	baseURL: import.meta.env.PROD ? "/api" : "http://localhost:8090",
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use(
	(config) => {
		if (!config.headers.Authorization) {
			const accessToken = getAccessToken();
			if (accessToken) {
				config.headers.Authorization = `Bearer ${accessToken}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error),
);

api.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response) {
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				removeAccessToken();

				const handleError = () => {
					removeAccessToken();
					removeRefreshToken();
					window.location.href = "/login";
				};

				if (originalRequest.url === "/users/refresh") {
					handleError();
					return Promise.reject(error);
				}
				try {
					const refreshToken = getRefreshToken();
					const { data } = await api.post("/users/refresh", { refreshToken });
					setAccessToken(data.accessToken);
					setRefreshToken(data.refreshToken);
					return api(originalRequest);
				} catch (refreshError) {
					handleError();
					return Promise.reject(refreshError);
				}
			}
		}

		return Promise.reject(error);
	},
);
export default api;

import React, { createContext, useState, ReactNode } from "react";
import { Snackbar, Alert, AlertColor, Slide, SlideProps } from "@mui/material";

interface SnackbarContextProps {
    openSnackbar: (message: string, variant?: AlertColor) => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({
    openSnackbar: () => { },
});

interface SnackbarProviderProps {
    children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
    children,
}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info");

    const handleSnackbarOpen = (message: string, severity?: AlertColor) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity || 'info');
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const slideTransition = (props: SlideProps) => {
        return <Slide {...props} direction="down" />;
    }
    return (
        <SnackbarContext.Provider value={{ openSnackbar: handleSnackbarOpen }}>
            {children}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={10000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                TransitionComponent={slideTransition}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{
                        width: "100%",
                        padding: 2,
                        border: "1px solid",
                        borderColor: (theme) => {
                            switch (snackbarSeverity) {
                                case "success":
                                    return theme.palette.success.main;
                                case "warning":
                                    return theme.palette.warning.main;
                                case "error":
                                    return theme.palette.error.main;
                                default:
                                    return theme.palette.info.main;
                            }
                        },
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider >
    );
};
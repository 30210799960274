import { useEffect, useState } from 'react';
import { getAllUsers } from '$services/userService';
import { UserPage } from '../User';
import { User } from '$types/auth';
import { PageLayout } from '$components/PageLayout';
import { Table } from '$components/Table';
import { Box } from '@mui/material';
import { useAuth, useSnackbar } from '$hooks/index';

const TABLE_HEADERS = ['Имя', 'Email', 'Роль']

export const UsersPage = () => {
    const { user } = useAuth()
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [createNewUser, setCreateNewUser] = useState(false);
    const { openSnackbar } = useSnackbar()

    const isAdmin = user?.role === 'admin';

    useEffect(() => {
        const fetchUsers = async () => {
            const res = await getAllUsers();
            if (res.success) {
                setUsers(res.data.users)
            } else {
                openSnackbar('Ошибка при загрузке пользователей', 'error');
            }
        };
        fetchUsers();
    }, []);


    const handleUserClick = (userData: any) => {
        if (!isAdmin) return null;
        setSelectedUser(userData)
    }

    const handleCreateUser = () => {
        if (user?.role !== 'admin') return;
        setCreateNewUser(true);
    };

    const handleCloseUser = () => {
        setSelectedUser(null);
        setCreateNewUser(false);
    };


    const renderRole = (role: string) => {
        return (
            <Box component="span" sx={{ px: 2, display: 'inline-flex', textAlign: 'center', textTransform: 'uppercase', fontSize: 12, fontWeight: 600, borderRadius: '16px', backgroundColor: 'primary.main', color: 'white' }}>
                {role}
            </Box>
        );
    };

    if (selectedUser || createNewUser) {
        return (
            <UserPage
                user={selectedUser}
                onClose={handleCloseUser}
            />
        );
    }


    return (
        <PageLayout buttonText='Создать' buttonFn={handleCreateUser} title='Пользователи' buttonIcon='plus' type='table'>
            <Table onRowClick={handleUserClick} data={users} headers={TABLE_HEADERS} keys={['name', 'email', 'role']} renderers={[null, null, renderRole]} />
        </PageLayout>
    );
};
import api from "$api";
import { IDictionary, IDictionaryArray, Doctor, Procedure } from "$types/dictionary";
import { ServiceResult } from "$types/service";
import { AxiosError } from "axios";

export const getDictionary = async (): Promise<
	ServiceResult<IDictionaryArray>
> => {
	try {
		const response = await api.get("/dictionary");
		const result = response.data.reduce(
			(acc: IDictionaryArray, item: IDictionary) => {
				const { id, type, label } = item;
				const key = `${type}s` as keyof IDictionaryArray;
				acc[key].push({ id, label });
				return acc;
			},
			{ doctors: [], procedures: [] },
		);
        // alphabetical  sort
		result.doctors.sort((a: Doctor, b: Doctor) => a.label.localeCompare(b.label));
		result.procedures.sort((a: Procedure, b: Procedure) => a.label.localeCompare(b.label));
		return { success: true, data: result };
	} catch (error) {
		return { success: false, error: (error as AxiosError).message };
	}
};


import React from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { Box, FormControl, InputLabel } from '@mui/material';
import { isValid } from 'date-fns';

interface PhoneInputProps {
    onPhoneChange: (phone: string) => void;
    value: string;
    error?: boolean
}

const PhoneInput: React.FC<PhoneInputProps> = ({ onPhoneChange, value, error, ...rest }) => {
    const handleChange = (newPhone: string) => {
        onPhoneChange(newPhone);
    };

    const isValid = (phone: string) => true;


    return (
        <Box mb={4}>
            <FormControl fullWidth variant="outlined" error={error}>
                <InputLabel htmlFor="phone-input">Номер телефона</InputLabel>
                <MuiTelInput
                    value={value}
                    onChange={handleChange}
                    defaultCountry="RU"
                    inputProps={{
                        id: 'phone-input',
                        label: 'Номер телефона',
                        required: true,
                    }}
                    error={!isValid(value)}
                    helperText={!isValid(value) ? 'Неверный формат номера телефона' : ''}
                />
            </FormControl>
        </Box>
    );
};

export default PhoneInput;